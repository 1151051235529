import * as actionTypes from '../actionTypes';
import axios from '../../../axios-defaults';
import {logout, authFailed} from "../auth";


export const companySearchStart = () => {
    return {
        type: actionTypes.COMPANY_SEARCH_START
    }
};

export const companySearch = (token, filter, showDeleted) =>{
    return dispatch =>{
    dispatch(companySearchStart());
        const requestData = {
            sid: token,
            searchFilter: filter.filterEmail !==''  ? filter.filterEmail : filter,
            showDeleted: showDeleted
        };

        let url = '/getCompanyList';

        axios.post(url,
            requestData).then(response =>{

            dispatch(companySearchSuccess(response.data));

        }).catch(err =>{
            if(err.response.data.status === actionTypes.NOT_AUTHENTICATED_ERROR_CODE){
                //we are no longer logged in/ re-authenticate.
                dispatch(logout());
            }else if(err.response.data.status === actionTypes.FORBIDDEN_ERROR){
                dispatch(authFailed(err.response.data.message));
                dispatch(logout());
            }else{
                dispatch(companySearchFailed(err.response.data.message));
            }
        })
    };
};

export const companySearchToggleDeleted = ( showDeleted ,token, filter )=>{

    return dispatch =>{
        dispatch(companySearchToggleDeletedSuccess(showDeleted));
        dispatch(companySearch(token, filter, showDeleted));
    };
};

export const companySearchToggleDeletedSuccess = (showDeleted) => {
    return {
        type: actionTypes.COMPANY_SEARCH_TOGGLE_DELETED_SUCCESS,
        showDeleted: showDeleted,
    }
};

export const companySearchFilter = ( data, filter) =>{
    return dispatch =>{

       // let filteredData = data.filter(item => String(item.name).toLowerCase().startsWith(String(filter.filterText).toLowerCase()));
        let filteredData = data.filter(item => String(item.name).toLowerCase().match(String(filter.filterText).toLowerCase()));
        if(filter.filterPostalCode !==''){
            filteredData = filteredData.filter(item => String(item.zipcode).toLowerCase().startsWith(String(filter.filterPostalCode).toLowerCase()));
        }

        //done filtering
        dispatch(companySearchFilterSuccess(filteredData));
    };
};

export const companySearchFilterSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_SEARCH_FILTER_SUCCESS,
        filteredData: data,
    }
};

export const companySearchSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_SEARCH_SUCCESS,
        searchData: data,
    }
};

export const companySearchFailed = (error) => {
    return {
        type: actionTypes.COMPANY_SEARCH_FAILED,
        error: error
    }
};