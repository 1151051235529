import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from "../../../shared/utility";

const initialState = {
    fieldControls:null,
    error: null,
    userMessage: null,
    data:null,
    commandSuccess:false,
};


const getUXJsonStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        fieldControls: null,
        commandSuccess:false,
        userMessage: null,
    });
};

const getUXJsonFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
    });
};

const getUXJsonSuccess = (state, action) =>{
    return updateObject(state, {
        fieldControls: action.fieldControls,
        error: null,
    });
};

export const clearUXFieldControls = (state, action) =>{
    return updateObject(state, {
        fieldControls: null,
        commandSuccess:false, //reset this so we don't get automatically redirected
        userMessage:null,
        data:null,
        error: null,
    });
};

const updateDataStart = (state, action) =>{
    return updateObject(state, {
        error: null,
        userMessage: null,
        data:null,
        commandSuccess:false,
    });
};

const updateDataFailed = (state, action) =>{
    return updateObject(state, {
        error: action.error ,
        userMessage:null,
        data:null,
        commandSuccess:false,
    });
};

const updateDataSuccess = (state, action) =>{
    return updateObject(state, {
        userMessage: action.userMessage,
        data: action.data,
        error: null,
        commandSuccess:true,
    });
};

const logout = (state, action) =>{
    return updateObject(state, {
        fieldControls:null,
        error: null,
        userMessage: null,
        data:null,
        commandSuccess:false,
    });
};

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.AUTH_LOGOUT: return logout(state, action);
        case actionTypes.UX_JSON_START: return getUXJsonStart(state, action);
        case actionTypes.UX_JSON_SUCCESS: return getUXJsonSuccess(state, action);
        case actionTypes.UX_JSON_FAILED: return getUXJsonFailed(state, action);
        case actionTypes.UX_JSON_CLEAR_CONTROLS: return clearUXFieldControls(state, action);

        case actionTypes.UPDATE_DATA_START: return updateDataStart(state, action);
        case actionTypes.UPDATE_DATA_SUCCESS: return updateDataSuccess(state, action);
        case actionTypes.UPDATE_DATA_FAILED: return updateDataFailed(state, action);

        default: return state;
    }

};

export default reducer;